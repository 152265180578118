import React from "react"
import { graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"
import { BasicPageTransition } from "../components/transitions"
import SEO from "../components/seo"
import { SliceZone } from "../components/slices"

const IS_BROWSER = typeof window !== "undefined"

const PageTemplate = ({ data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
  const data = mergePrismicPreviewData({ staticData, previewData })
  const {
    meta_title,
    meta_description,
    keywords,
    body,
  } = data.prismicBasicPage.data
  const keywordArray = keywords ? keywords.split(",") : []
  return (
    <BasicPageTransition>
      <SEO
        title={meta_title.text}
        description={meta_description.text}
        keywords={keywordArray}
      />
      {body && <SliceZone slices={body} />}
    </BasicPageTransition>
  )
}

export const pageQuery = graphql`
  query pageBySlug($uid: String!) {
    prismicBasicPage(uid: { eq: $uid }) {
      uid
      data {
        keywords
        meta_description {
          text
        }
        meta_title {
          text
        }
        title {
          text
        }
        body {
          ... on PrismicBasicPageBodySpacer {
            id
            slice_type
            primary {
              size
            }
          }
          ... on PrismicBasicPageBodyStandardBanner {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2880, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                thumbnails {
                  mobile {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1000, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
              text {
                html
              }
              show_read_more_link
            }
          }
          ... on PrismicBasicPageBodyFeatureBanner {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2880, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                thumbnails {
                  mobile {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1000, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
              link_text {
                text
              }
              text {
                html
              }
              page_reference {
                url
              }
            }
            items {
              statistic_end_value {
                text
              }
              statistic_factor {
                text
              }
              statistic_start_value {
                text
              }
              statistic_title {
                text
              }
              statistic_units {
                text
              }
              statistic_decimals
            }
          }
          ... on PrismicBasicPageBodyTwoColumnText {
            id
            slice_type
            primary {
              include_in_page_nav
              text {
                html
              }
              slice_title {
                text
              }
              link_title {
                text
              }
              link {
                url
              }
              page_nav_title {
                text
              }
            }
          }
          ... on PrismicBasicPageBodySingleColumnText {
            id
            slice_type
            primary {
              include_in_page_nav
              link {
                url
              }
              link_text {
                text
              }
              slice_title {
                text
              }
              text {
                html
              }
              page_nav_title {
                text
              }
            }
          }
          ... on PrismicBasicPageBodyPageIntro {
            id
            slice_type
            primary {
              text {
                html
              }
              show_page_nav
            }
          }
          ... on PrismicBasicPageBodyEnquiryPrompt {
            id
            slice_type
            primary {
              link {
                url
              }
              link_text {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicBasicPageBodyImage {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2880, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicBasicPageBodyImageText {
            id
            slice_type
            primary {
              include_in_page_nav
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2880, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              reverse_layout
              text {
                html
              }
              slice_title {
                text
              }
              page_nav_title {
                text
              }
            }
          }
          ... on PrismicBasicPageBodyTextBackgorundImage {
            id
            slice_type
            primary {
              title1 {
                text
              }
              link {
                url
              }
              link_text {
                text
              }
              text {
                html
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2880, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicBasicPageBodyStatistics {
            id
            slice_type
            items {
              end_value {
                text
              }
              start_value {
                text
              }
              units {
                text
              }
              value_suffix {
                text
              }
              value_decimals
              statistic_title {
                text
              }
            }
          }
          ... on PrismicBasicPageBodyInformationGrid {
            id
            slice_type
            primary {
              include_in_page_nav
              slice_title {
                text
              }
              page_nav_title {
                text
              }
            }
            items {
              text_section {
                html
              }
            }
          }
          ... on PrismicBasicPageBodyEnquiryForm {
            id
            slice_type
          }
          ... on PrismicBasicPageBodyCareersForm {
            id
            primary {
              form_button_text {
                text
              }
              button_link {
                url
              }
              include_in_page_nav
              page_nav_title {
                text
              }
              slice_title {
                text
              }
              text {
                html
              }
            }
            slice_type
          }
        }
      }
    }
  }
`

export default PageTemplate
